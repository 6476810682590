.navBar {
	/* position: sticky;
	top: 0;
	 */
	list-style-type: none;
	text-decoration: none;
	width: 100%;
	height: 3em;
	margin: auto;
	padding: 15px 0 10px 0;

	display: flex;
	justify-content: space-around;
	z-index: 900;

	background-color: white;
}

.backgroundGray {
	background-color: #f6f6f6;
}

.navLink, .navLink:hover, .navLink:focus, .navLink:active {
	text-decoration: none;
	color: black;
}

.navLink:hover {
	color: #fbad18;
}

.sticky {
	/* position: -webkit-sticky; */
	position: sticky;
	top: 0;
	z-index: 9999;
}

.dropDown {
	width: 150px;
    transition-duration: 0.4s;
	height: fit-content;
	padding: 0 10px;
	background-color: white;
	border-radius: 15px;
}

.dropDown:hover {
	cursor: pointer;	
}

.dropDown.up {
	color: #3b74ba;
}

.dropDown.up:hover {
    text-shadow: #fbad18 1px 0 10px;
	color: #fbad18;
}

.dropDown.down:hover {
    text-shadow: #3b74ba 1px 0 20px;
	color: #3b74ba
}

.dropDown.down {
    text-shadow: #fbad18 1px 0 10px;
	color: #fbad18;
}

.dropDown span {
	display: inline-block;
	transition-duration: 0.4s;
}

.dropDown span.up {
	transform: rotate(180deg);
}

.dropDownList {
	padding: 12px;
}

.dropDownList li {
	list-style-type: none;
	padding: 0;
	color: #fbad18;
}
