.card {
	margin: 50px;
}

.linkImg {
	margin: 20px 0 0 20px;
	width: 440px;
	height: 260px;
	overflow: hidden;
}

.linkImg img:hover {
	opacity: .5;
	transform: scale(1.2);
}

.card img {
	transition: opacity .5s ease, transform 5s ease-out;
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.borderImg {
	border: double #f0609e;
}

.card h2 {
	margin: 0 0 0 10px;
}

.container {
	display: flex;
	justify-content: center;
	gap: 3vw;
}

.children {
	width: 540px;
	margin-left: 20px;
}
.children p {
	margin: 0 0 10px 0;
}

@media (max-width: 1247px) {
	.card img {
		width: 300px;
	}
	.children {
		width: 600px;
		flex: 4 1 auto;
	}
}

/* Mobile styles */
.cardMobile {
	margin: 0;
}

.containerMobile {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.childrenMobile h2 {
	width: fit-content;
}
.childrenMobile p {
	margin-top: -5px;
}


.red {
	color: #f04e32;
}
.blue {
	color: #3b74ba;
}
.pink {
	color: #f0609e;
}
.yellow {
	color: #fbad18;
}