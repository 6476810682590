/*  reset form styles  */
.form input:focus {
	outline: none;
}
.form textarea:focus {
	outline: none;
}

.form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 800px;;
	margin: auto;
}

.input {
	border: 2px solid #dadada;
    border-radius: 7px;
}

.contact {
	display: flex;
	gap: 30px;
	margin-bottom: 10px;
}

.contact input {
	margin-left: 5px;
}

.message {
	margin-bottom: 8px;
}

.need {
	outline: none;
    border-color: #ed9e9e;
    box-shadow: 0 0 10px #ed9e9e;
}

.form input:focus {
	border-color: #fbad18;
}
.form textarea:focus {
	border-color: #fbad18;
}

.form textarea {
	height: 80px;
}

.form input[type=submit] {
	background-color: #f0609e;
	border-radius: 7px;
	border: none;
	color: white;
	padding: 3px 32px;
	text-decoration: none;
	margin: 8px 2px 4px 2px;
	cursor: pointer;
}

.show {
	display: flex;
	animation: animateConf;
	animation-delay: 0.5s;
	animation-duration: 0.5s;
}
@keyframes animateConf {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.hide {
	display: none;
}

.confirmation {
	position: relative;
	top: 30%;
	left: 25%;
	background-color: #ffffff;
	width: 50%;
	height: 100px;
	margin-bottom: -100px;
	color: #f0609e;
	text-align: center;
	font-weight: 500;
	border: 0.5px dashed #f0609e;
	border-radius: 5px;
}

/*  Mobile styles  */
.formMobile input:focus {
outline: none;
}
.formMobile textarea:focus {
	outline: none;
}
.formMobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 90%;
	margin: auto;
}

.formMobile input:focus {
	border-color: #fbad18;
}
.formMobile textarea:focus {
	border-color: #fbad18;
}
.formMobile input[type=submit] {
	background-color: #f0609e;
	border-radius: 7px;
	border: none;
	color: white;
	padding: 3px 32px;
	text-decoration: none;
	margin: 8px 2px 4px 2px;
	cursor: pointer;
}

.formMobile .contact {
	display: flex;
	flex-direction: column;
}

.mobileFormField {
	padding: 0 2px;
	background-color: #3b74ba44
}