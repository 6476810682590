.menu {
    border: 2px solid #3b74ba80;
    border-top: none;
    border-radius: 5px;
}

.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.menu li {
    padding: 3px 5px;
    letter-spacing: 4px;
    font-size: 1.1em;
}
