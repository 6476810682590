.blogWrapper {
    background-color: #3b74ba41;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    margin: 10vh 5vw;
}

.titleBar {
    padding-top: 5vh;
}

.titleBar h1 {
    font-size: 90pt;
	padding: 15px;
	line-height: .84em;
	letter-spacing: -.7rem;
    margin: 0;
    padding-left: 15vw;
    margin-bottom: -1vw;
    color: #456ec7;
}

.bodyWrapper {
    display: flex;
}

.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.banner h1 {
    margin-left: 10vw;
    font-size: 10vw;
    line-height: .84em;
    letter-spacing: -.27rem;
    color:rgba(0, 0, 0, 0.623);
}

.button {
    font-size: 10vh;
}

.post {
    padding: 1vh 0;
    padding-left: 3vw;
    padding-right: 1vw;
    margin: 5%;
    margin-top: 0;
	background-color: #3b74ba5d;
    border-radius: 7px;
}

.menu {
    border: 2px solid #3b74ba80;
    border-radius: 5px;
    width: 600px;
    flex: 0 5 auto;
    margin-left: 1vw;
    margin-top: 3vw;
    padding-left: 2vw;
    padding-bottom: 100px;
    height: fit-content;
    position: sticky;
    top: 25px;
}

.blog {
    text-align: center;
    flex: 1 7 auto;
    display: flex;
    flex-direction: column;
}

.spacer {
    height: 10vh;
    border-radius: 4px;
    margin: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.red {
	color: #f04e32;
}
.blue {
	color: #3b74ba;
}
.pink {
	color: #f0609e;
}
.yellow {
	color: #fbad18;
}