.wrapper {
	font-size: 16pt;
	font-weight: 200;
	margin: 50px 100px;
	background-color:#fbac1826;
}

.wrapperMobile, .wrapperMobile * {
	box-sizing: border-box;
}

.wrapperMobile {
	font-weight: 300;
	margin: 0 10px;
	padding: 10px 5px;
	background-color:#fbac1826;
}

.mobileHOne {
	font-size: 4em;
	line-height: .8em;
	letter-spacing: -0.3rem;
	margin: 0;
}

.wrapper h1 {
	font-size: 90pt;
	padding: 15px;
	line-height: .84em;
	letter-spacing: -.7rem;
	margin: 0;
}
.header .top {
	background-color: #fefefe;
}

.header h2 span {
	display: contents;
	padding: 0;
	margin: 0;
}

.thicker {
	font-weight: 400;
}

.experienceWrapper {
	margin: 20px;
	padding-bottom: 1px;
}

.wrapperMobile .experienceWrapper {
	margin: 0 12px;
}

.timeline *{
	padding: 0;
	margin: 0;
}

.timeline {
	position: relative;
	max-width: 1200px;
	background-color: #fbac1872;
	margin: 0 auto;
}

.timeline::after{
	content: '';
	position: absolute;
	width: 6px;
	height: 100%;
	background: #ffff;
	top: 0;
	left: 50%;
	margin-left: -3px;
	z-index: 1;
	animation: moveline 10s linear infinite;
	animation-delay: 0.5s;
	opacity: 0;
}
@keyframes moveline {
	0% { 
		opacity: 1;
		height: 0;
	}
	50% { 
		height: 100%;
		/* transform: scaleY(1); */
	}
	100% {
		/* height: 100%; */
		transform: scaleY(0);
		transform-origin: 0 100%;

	}
}

.timeline::before{
	content: '';
	position: absolute;
	width: 6px;
	height: 100%;
	background: #ffff;
	top: 0;
	left: 50%;
	margin-left: -3px;
	z-index: 1;
	animation: moveline 10s linear infinite;
	opacity: 0;
	animation-delay: 5s;
}
@keyframes moveline {
	0% { 
		opacity: 1;
		height: 0;
	}
	50% { 
		height: 100%;
		opacity: 1;
		/* transform: scaleY(1); */
	}
	100% {
		/* height: 100%; */
		opacity: 1;
		transform: scaleY(0);
		transform-origin: 0 100%;

	}
}

.lightBold {
	font-weight: 300;
}

.textBox {
	padding: 20px 30px;
	background-color: #fefefe;
	position: relative;
	border-radius: 6px;
	font-size: 15px;	
}

.textBoxMobile {
	width: 100%;
}

.textBoxMobile p {
	padding: 0 3px;
}

.textBox h2 {
	font-weight: 600;;
	word-wrap: break-word;
}

.textBox small {
	display: inline-block;
	margin-bottom: 13px;
}

.item {
	padding: 10px 50px;
	position: relative;
	width: 50%;
}

.leftItem {
	left: 0;
}

.rightItem {
	left: 50%;
}

.itemMobile {
	padding: 0 5px;
	margin-bottom: 20px;
	position: static;
	left: 10%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f6c97687;
	font-size: 1.2em;
}

.item img {
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid rgb(96, 96, 96);
	right: -20px;
	top: 32px;
	z-index: 10;
}

.itemMobile h2 {
	text-align: center;
}

.itemMobile img {
	position: static;
	margin: 0 auto;
	margin-top: 5px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid rgb(96, 96, 96);
}

.rightItem img {
	left: -20px;
}

.leftArrow {
	height: 0;
	width: 0;
	position: absolute;
	top: 38px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid #fff;
	z-index: 1;
	right: 28px;
}

.rightArrow {
	height: 0;
	width: 0;
	position: absolute;
	top: 38px;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 15px solid #fff;
	z-index: 1;
	left: 28px;
}


.red {
	color: #f04e32;
}
.blue {
	color: #3b74ba;
}
.pink {
	color: #f0609e;
}
.yellow {
	color: #fbad18;
}