.game {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 90vh;
    margin: auto;
    font-size: 15pt;
    font-weight: 500;
}

.game h1 {
    font-size: 15vw;
	padding: 15px;
	line-height: .84em;
	letter-spacing: -.7rem;
    color: #f04e32a4;
    flex: 1 2 auto;
}

.titleCard {
    display: flex;
    gap: 25px;
}

.titleCard div {
    display: flex;
    align-items: center;
}

.titleCard p {
    min-height: 300px;
    border: solid 3pt #3b74ba83;
    border-radius: 10px;
    padding: 30px 50px;
    font-weight: 400;
    font-size: 16pt;
}

.dealButton {
    width: 40vw;
    background-color: white;
    border: none;
    color: #f0609e;
    border: solid 1px #f0609e;
    border-radius: 10px;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 25px auto;
}

.dealButton {
    transition-duration: 0.4s;
  }
  
  .dealButton:hover {
    background-color: #f0609e; /* Green */
    color: white;
  }

.top {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50vh;
}

.field{
    width: 100%;
    height: 300px;
    min-height: 180px;
    border: solid #3b74ba;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    transition-duration: 0.4s;
}
.field:hover {
    background-color: #3b74ba36;
}

.fieldPile {
    width: 100px;
    height: 132px;
    margin: 10px;
    margin-bottom: 3vh;
    border: dashed #3b74baa1;
    border-top: none;
    border-radius: 5px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 1px;
    padding-bottom: 3px;
}

.card {
    width: 80px; 
    height: 35px; 
    padding: 1px 3px;
    padding-left: 10px;
    background-color: black;
    flex: 0 0 auto;
    white-space: pre-line;
    border-radius: 4px;
}

.card.up {
    border: solid 1px; 
    border-color : #04AA6D;
    background-color: white;
    transition-duration: 0.4s;
    transition-property: background-color, font-weight;
    cursor: pointer;
}
.card.up:hover {
    background-color: #04aa6d21;
    font-weight: 900;
}

.card.child {
    border-color: yellow;
    background-color: white;
}


.stock {
    width: 50vw;
    min-width: 670px;
    height: 90%;
    overflow: auto;
    padding: 0 40px;
    border: solid #f04e32;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transition-duration: 0.4s;
    position: relative;
}
.stock:hover {
    background-color: #f04e3296;
    cursor: pointer;
}

.stock .button {
    width: 60px;
    height: 80px;
    border: solid 1px #f0609e;
    border-radius: 5px;
    color: #f0609e;
    font-size: 28pt;
    background-color: white;
    transition-duration: 0.4s;
}
.stock .button:hover {
    background-color: #f0609ec2;
    color: white;
}

.stock .card {
    width: 50px;
    height: fit-content;
    min-height: 100px; /* going below will cause text to clip out*/
    padding-top: 5px; /* to match left */
    border-right: dashed 1px white;
    flex: 1 1 auto;
    font-size: 1.5em;
    cursor: default;
}

.stock .card.hearts, .stock .card.diamonds {
    border-color: #f04e32;
}

.stock .card.up {
    border: solid 2px green;
    cursor: pointer;
}

.stockHelpMessage {
    position: absolute;
    top: 10px; 
    left: 30px; 
    color: #f04e32;
    opacity: 0;
    transition-duration: 0.4s;
}
.stock:hover .stockHelpMessage {
    opacity: 1;
}

.goal {
    width: 30vw;
    height: 70%;
    min-height: 135px;
    padding: 10px 0;
    border: solid #fbad18;
    border-radius: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition-duration: 0.4s;
}
.goal:hover {
    background-color: #fbac182d;
}

.goal .card.up:hover {
    background-color: #ffce73;
}

.goalPile {
    width: 80px;
    height: 112px;
    border: dashed #fbad18;
    flex: 0 1 auto;
}

.goalPile.empty {
    opacity: .5;
    font-size: 3.5em;
    color: #fbad18;
    text-align: center;
}    

.currentSelection {
  height: 25px;
  width: 100%;
  opacity: .5;
  text-align: center;
  flex: 0 0 auto;
}

.goalPile .card {
    width: 110%;
    height: 110%;
    margin-left: -5%;
    margin-top: -5%;
}

.clubs, .spades {
    color: white
}

.up.clubs, .up.spades {
    color: black;
}

.hearts, .diamonds {
    color: #f04e32;
}



.red {
	color: #f04e32;
}
.blue {
	color: #3b74ba;
}
.pink {
	color: #f0609e;
}
.gold {
    text-shadow: #fbad18 1px 0 10px;
}

body.active-modal {
    overflow-y: hidden;
}