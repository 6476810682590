.wrapper {
	font-size: 16pt;
	font-weight: 300;
}

.firstWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	background-color: #f6f6f6;
	padding-bottom: 50px;
}

.header {
	margin-top: 100px;
	padding: 5px 10px;
}
.header h1 {
	font-size: 20vw;
	margin: 0;
	line-height: .84em;
}
.header .top {
	background-color: #fefefe;
}
.header h2 {
	font-size: 10vw;
	line-height: .84em;	
	margin: -2px 0 0 0;
	width: auto;
}
.header h2 span {
	display: contents;
	padding: 0;
	margin: 0;
}
.hide{
	visibility: hidden;
	font-weight: 100;
}

.blinking {
	width: 30px;
}

.body {
	margin-top: 10px;
	width: 80%;
	flex: 1 1 auto;
}

.body span {
	display: inline-block;
	background-color: #fefefe;
	margin-bottom: 3pt;
	padding: 0 5px 0 5px;
}
.body .hello {
	margin-bottom: 10px;
}

.thicker {
	font-weight: 400;
}

.secondWrapper {
	width: 80%;
	margin: 0 auto;
}

.projectsWrapper {
	margin: 0;
}

.thirdWrapperWide {
	width: 100%;
	background-color: #f6f6f6;
	padding: 1px 0 100px 0;
}

.thirdWrapper {
	width: 80%;
	margin: 0 auto;
}


.projectsHeader.sticky {
	background-color: #f0609e27;
	width: fit-content;
	padding: 0 5px 0 5px;
	position: sticky;
	top: 1em;
	z-index: 10;
}



.red {
	color: #f04e32;
}
.blue {
	color: #3b74ba;
}
.pink {
	color: #f0609e;
}
.yellow {
	color: #fbad18;
}